import { CIEnvironmentConfiguration } from '../environment-config';

export const environment: CIEnvironmentConfiguration = {
    production: true,
    organizationUUID: '45b42b5d-9bda-4e01-b157-5a3baeae7ff9',
    authenticationUrl: 'https://auth.datalean-nodejs-preprod.catalean.com/',
    mediaLibraryUrl: 'https://media-library.datalean-nodejs-preprod.catalean.com/media-library/v2/',
    configUrl: 'https://configuration.datalean-nodejs-preprod.catalean.com',
    userUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/application/v2/',
    practiceUrl: "https://consenso-informato-module.datalean-nodejs-preprod.catalean.com/practice/",
    practiceSectionContainerUrl: "https://consenso-informato-module.datalean-nodejs-preprod.catalean.com/practice-section-container/",
    practiceTemplateUrl: 'https://consenso-informato-module.datalean-nodejs-preprod.catalean.com/practice-template/',
    patientUrl: 'https://consenso-informato-module.datalean-nodejs-preprod.catalean.com/patient/',
    practiceSectionUrl: 'https://consenso-informato-module.datalean-nodejs-preprod.catalean.com/practice-section/',
    pageSize: 15,
    availableLocales: ['it-IT'],
    defaultLocale: 'it-IT',
    registration: {
        enabled: false,
        url: window.location.origin + '/registration',
    },
};
